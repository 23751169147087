export const getPricingTier = (country, type, settings) => {
  //console.log(country, type, settings)
    let tier = 'high'
    if (settings.low_income_countries.includes(country)) {
      tier = 'low'
    }
  if (['ASN','PF','INS','PD','PD2'].includes(type)) {
    if (tier === 'high') {
      return settings.corporation_rate_high_income
    } else {
      return settings.corporation_rate_low_income
    }
  }
  if (['MI1','NGO'].includes(type)) {
    if (tier === 'high') {
      return settings.ngo_rate_high_income
    } else {
      return settings.ngo_rate_low_income
    }
  }
  if (['ACH','CON','CON2'].includes(type)) {
    if (tier === 'high') {
      return settings.university_rate_high_income
    } else {
      return settings.university_rate_low_income
    }
  }
}
