import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import axios from 'axios'

const ResetPassword = (props) => {
  const userId = props.match.params.id
  const [password, setPassword] = useState()
  let redirect = ''
  if (props.location.search) {
    const split = props.location.search.split('?redirect=')
    redirect = split[1]
  }
  return(
    <div className="wrapper">
      <Form
        layout="vertical"
        onFinish={(values) => {
          axios.post(`${process.env.REACT_APP_API_URL}/session/new-password`, {
            id: userId,
            password: values['password']
          })
          .then(() => {
            message.success('Password changed')
            setTimeout(() => {
              window.location.href = redirect
            }, 1000)
          })
          .catch((err) => {
            message.error('An error ocurred')
          })
        }}
      >
      <h1>Reset Password</h1>
      <Form.Item
      label="New password"
      name="password"
      rules={[
        {
          required: true,
        },
        { min: 8, message: 'Password must be a minimum 8 characters.' },
      ]}
      >
       <Input.Password size="large" placeholder="password" />
      </Form.Item>
      <Form.Item
      label="Confirm new password"
      name="confirm-password"
      rules={[
        {
          required: true
        },
        { min: 8, message: 'Password must be a minimum 8 characters.' },
      ]}
      >
       <Input.Password size="large" placeholder="password" />
      </Form.Item>
      <Button size="large" type="primary" htmlType="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default withRouter(ResetPassword)
