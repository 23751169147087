import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Alert, Form, Button, message } from 'antd'
import axios from 'axios'

const Auth = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [showTempPassword, setShowTempPassword] = useState(false)
  const [email, setEmail] = useState()
  const [userId, setUserId] = useState()
  let redirect = `${process.env.REACT_APP_SITE_URL}/`
  if (props.location.search) {
    const split = props.location.search.split('?redirect=')
    redirect = `${process.env.REACT_APP_SITE_URL}${split[1]}`
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/session/status`, {
      withCredentials: true
    })
    .then((res) => {
      console.log(res)
      if (res.data.auth === true) {
        window.location.href = process.env.REACT_APP_SITE_URL
      }
    })
  }, [])

  return(
    <div className="wrapper">
      <Form
       onFinish={(values) => {
         if (values['email'] && !values['password']) {
         axios.get(`${process.env.REACT_APP_API_URL}/users?email=${values['email']}`
         ).then((res) => {
           if (res.data[0].id) {
             setEmail(values['email'].trim())
             setUserId(res.data[0].id)
             if (res.data[0].reset_password === true) {
               setResetPassword(true)
               axios.post(`${process.env.REACT_APP_API_URL}/session/reset-password`, {
                 email: values['email'].trim(),
                 id: res.data[0].id
               })
               .then(() => {
                 setShowTempPassword(true)
               })
             }
             setShowPassword(true)
           } else {
             message.error('Username or password incorrect')
           }
         }).catch((err) => {
           setShowPassword(false)
           message.error('Username or password incorrect')
         })
       } else {
         axios.post(`${process.env.REACT_APP_API_URL}/auth/local`,
           {
             identifier: values['email'].trim(),
             password: values['password'].trim()
           },
           {
              withCredentials: true
           }
         ).then((res) => {
           message.success('Sign in sucessful')
           if (resetPassword) {
             props.history.push(`/reset-password/${userId}?redirect=${redirect}`)
           } else {
              window.location.href = redirect
           }
         }).catch((err) => {
           message.error('Username or password incorrect')
         })
       }
       }}
       omFinishFailed={(values, errors) => {
         console.log(errors)
       }}
       layout="vertical"
      >
      <h1>Sign in</h1>
      { !showTempPassword &&
        <p>Enter the email address associated with your MiN account.</p>
      }
      { showTempPassword &&
        <p>
        <Alert
           message="Warning"
           description="An email with a temporary password was sent to your address."
           type="warning"
           showIcon
         />
        </p>
      }
      <Form.Item
       label="Email"
       name="email"
       rules={[
         {
           required: true,
           type: 'email'
         }
       ]}
      >
      <Input size="large" placeholder="email" />
      </Form.Item>
      { showPassword &&
      <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true
        }
      ]}
      >
      <Input.Password size="large" placeholder="password" />
      </Form.Item>
      }
      <div className="auth-help">
        <p><a href="/sign-up">Don't have an account ? Create an account!</a></p>
      </div>
      <Button size="large" type="primary" htmlType="submit">Submit</Button>
      </Form>
      { showPassword && !resetPassword &&
      <div className="auth-help">
        <p>
          <a
           href="#reset-password"
           onClick={(e) => {
             e.preventDefault()
             setResetPassword(true)
             axios.post(`${process.env.REACT_APP_API_URL}/session/reset-password`, {
               email: email,
               id: userId
             })
             .then(() => {
               setShowTempPassword(true)
             })
           }}
           >
           Forgot your password?
          </a>
        </p>
      </div>
      }
    </div>
  )
}

export default withRouter(Auth)
