import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Form, Button, Select } from 'antd'
import axios from 'axios'
import { Alert } from '@mui/material';


const Vatform = (props) => {

  const [params, setParams] = useState(new URLSearchParams(props.location.search));

  const countries = [
    { value: 'AT', label: 'Austria' },
    { value: 'BE', label: 'Belgium' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'CZ', label: 'Czechia' },
    { value: 'DE', label: 'Germany' },
    { value: 'DK', label: 'Denmark' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ES', label: 'Spain' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GR', label: 'Greece' },
    { value: 'HR', label: 'Croatio' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IT', label: 'Italy' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LV', label: 'Latvia' },
    { value: 'MT', label: 'Malta' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'PO', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'RO', label: 'Romania' },
    { value: 'SE', label: 'Sweden' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'OTHER', label: 'Non-EU member'}
  ];

  const [addressRequired, setAddressRequired] = React.useState(false);
  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_URL}/session/status`, {
      withCredentials: true
    })
    .then((res) => {
      if (res.data.auth !== true) {
        window.location.href = process.env.REACT_APP_SITE_URL
      }
    })
  }, [])

  async function checkVies(values) {
    return axios.post(`${process.env.REACT_APP_API_URL}/vies/check`, values, { withCredentials: true });
  }

  return(
    <div className="wrapper">
      <Form
       onFinish={(values) => {
        setParams(new URLSearchParams(props.location.search));
        checkVies(values).then((data) => {
          if(!params.get('resource')) {
            window.location.href = process.env.REACT_APP_SITE_URL;
          } else {
            window.location.href = `${process.env.REACT_APP_SITE_URL}/resources/${params.get('resource')}`
          }

        });
       }}
       onFinishFailed={(values, errors) => {
         console.log(errors)
       }}
       layout="vertical"
      >
        <h1>VAT Information</h1>
          <Alert style={{marginBottom: 25}} severity="warning">Please select your country.
          Leave the VAT number field empty if you are not registered with VAT</Alert>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
              }
            ]}
          >
          <Select
            size="large"
          >
          { countries.map((country) => {
            return <Select.Option key={country.value} value={country.value}>{country.label}</Select.Option>
          })}
          </Select>
          </Form.Item>
          <Form.Item
          label="VAT number (excluding country code)"
          name="vat_number"
          rules={[
            {
              required: false,
            }
          ]}
          >
            <Input size="large" placeholder="VAT number" onChange={(text) => setAddressRequired(text.target.value !== '')}/>
          </Form.Item>
          <Form.Item
            label="Postal code"
            name="postal_code"
            rules={[
            {
              required: addressRequired,
            }
          ]}
          >
            <Input size="large" placeholder="Postal code"/>
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[
            {
              required: addressRequired,
            }
          ]}
          >
          <Input size="large" placeholder="Address"/>
        </Form.Item>
        <Button size="large" type="primary" htmlType="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default withRouter(Vatform)
