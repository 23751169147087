import React, { useState, useEffect } from 'react'
import { Input, Select, Checkbox, InputNumber, Form, Row, Col, Button } from 'antd'
import { numberWithCommas, makeId } from '../../helpers.js'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import './style.css'
const { Option } = Select

const SignUpForm = (props) => {
  const [settings, setSettings] = useState()
  const [priceTier, setPriceTier] = useState('high')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/membership-settings`)
    .then((res) => {
      setSettings(res.data)
    })
  }, [])

  return (
    <div className="wrapper">
      <h1>Individual Membership</h1>
      <Form
        layout="vertical"
        scrollToFirstError={true}
        onFinish={(values) => {
          let price
          if (priceTier === 'high') {
            price = settings.individual_rate_high_income
          } else {
            price = settings.individual_rate_low_income
          }
          values['member-price-tier'] = priceTier
          values['member-price'] = price
          axios.post(`${process.env.REACT_APP_API_URL}/members`,
          {
            firstName: values['first-name'],
            lastName: values['last-name'],
            phoneNumber: values['phone-number'],
            member_name: `${values['first-name']} ${values['last-name']}`,
            member_email: values['email'],
            member_type: 'Individual',
            member_status: 'pending_payment',
            member_application_data: values,
            member_id: makeId(3),
            language: 'en',
            dateOfJoining: new Date(),
            country: values['country'],
            payingPrice: `${price}€`

          }).then((res) => {
            props.history.push('/confirm')
          })
        }}
        onValuesChange={(values) => {
          if (values['country']) {
            const country = values['country']
            let tier = 'high'
            if (settings.low_income_countries.includes(country)) {
              tier = 'low'
            }
            setPriceTier(tier)
          }
        }}
      >

      {/*} -------- CONTACT INFO ------------ {*/}
      <div className="form-section">
      <h2>Contact information</h2>
        <Form.Item
          label="First name"
          name="first-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input
           size="large"
         />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="last-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Confirm Email"
          name="email-confirm"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phone-number"
        >
         <Input size="large" />
        </Form.Item>
        </div>


{/*} -------- ADDRESS ------------ {*/}
<div className="form-section">
      <h2>Address</h2>
        <Form.Item
          label="Address line 1"
          name="address-line-1"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Address line 2"
          name="address-line-2"
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Postal code"
          name="postal-code"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
            }
          ]}
        >
        { settings &&
         <Select
          size="large"
          showSearch
          style={{width: '100%'}}
         >
          { settings.low_income_countries.map((item, index) =>
            <Option key={`low-${index}`} income="low" value={item}>{item}</Option>
          )}
          { settings.high_income_countries.map((item, index) =>
            <Option key={`hi-${index}`} income="high" value={item}>{item}</Option>
          )}
         </Select>
       }
        </Form.Item>
        </div>

      {/*} --------- MEMBER INTERESTS ------------ {*/}
      <div className="form-section">
        <h2>Member profile</h2>
        <Form.Item
          label="I am joining the Microinsurance Network because I am interested in..."
          name="interests"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Select
         style={{ width: '100%' }}
         size="large"
         mode="multiple"
        >
          <Option value="expert-forums">Expert forums</Option>
          <Option value="networking">Networking</Option>
          <Option value="country-workshops">Country workshops</Option>
          <Option value="best-practice-groups">Best Practice Groups</Option>
          <Option value="premimum-content">Premium content</Option>
        </Select>
        </Form.Item>
        <Form.Item
          label="How would you like to contribute to the Network's activities?"
          name="activities"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Select
         style={{ width: '100%' }}
         size="large"
         mode="multiple"
        >
          <Option value="attend-events">Attend our events</Option>
          <Option value="bring-new-members">Bring in new members</Option>
          <Option value="host-workshops">Support organisation of workshops/Expert Forums</Option>
          <Option value="moderate-forum">Moderate an Export Forum</Option>
          <Option value="sponsor-activities">Sponsor one or more activities</Option>
          <Option value="chair-best-practice-group">Chair a Best Practice Group</Option>
          <Option value="write-content">Write blogs, articles or research papers</Option>
        </Select>
        </Form.Item>
        <Form.Item
          label="How did you hear about us?"
          name="referal"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Select
         size="large"
         style={{ width: '100%' }}
        >
          <Option value="employer">Employer</Option>
          <Option value="event">Event</Option>
          <Option value="friend">Friend or colleague</Option>
          <Option value="internet">Internet search</Option>
          <Option value="current-member">Current Network member</Option>
          <Option value="social-media">Social media</Option>
          <Option value="website">Website</Option>
          <Option value="word-of-mouth">Word-of-mouth</Option>
        </Select>
        </Form.Item>
      </div>

      {/*} -------- PAYMENT INFO ------------ {*/}
      <div className="form-section">
        <h2>Payment information</h2>
        <Row className="product-item" gutter={20}>
        <Col xs={24} sm={16}>
          Annual recurring individual membership
        </Col>
          <Col xs={24} sm={8}>
          {settings &&
          <div className="price">
          	&euro;
          { priceTier === 'high' &&
            numberWithCommas(settings.individual_rate_high_income)
          }
          { priceTier === 'low' &&
            numberWithCommas(settings.individual_rate_low_income)
          }
          </div>
          }
            per year
          </Col>
        </Row>
      </div>

      {/*} -------- LEGAL INFO ------------ {*/}
      <div className="form-section">
        <h2>Terms and conditions</h2>
        <Form.Item
          name="review-mission"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Checkbox.Group>
          <Checkbox value="yes">
          I commit to the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/vision-and-mission`}>vision and mission</a>  and have read, understood and agree to support and comply with the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/bylaws`}>by-laws</a> of the Microinsurance Network.
          </Checkbox>
        </Checkbox.Group>

      </Form.Item>
      <Form.Item
        name="review-privacy"
        rules={[
          {
            required: true,
          }
        ]}
      >
      <Checkbox.Group>
      <Checkbox value="yes">
      I hereby consent to the processing of my personal data that I have provided for the purposes described in the <a target="blank" href="https://microinsurancenetwork.org/privacy">Privacy statement</a>.
      </Checkbox>
      </Checkbox.Group>
      </Form.Item>
      </div>


      <div className="form-section">
        <p>Submit your application for individual membership by clicking the button below.</p>
        <Button
         htmlType="submit"
         type="primary"
         size="large"
        >
        Submit membership application
        </Button>
        </div>
      </Form>

    </div>
  )
}

export default withRouter(SignUpForm)
