import { message } from 'antd'
import axios from 'axios'

export const unsubscribeUser = (id) => {
  axios.put(`${process.env.REACT_APP_API_URL}/unsubscribe`, {
    id: id
  })
  .then((res) => {
    message.success('Email address was unsubscribed')
  })
  .catch((err) => {
    message.error('An error ocurred')
  })
}
