import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Form, Button, Checkbox, Select, message } from 'antd'
import axios from 'axios'
const { Option } = Select

const SubscriberSignUp = (props) => {
  const [settings, setSettings] = useState()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/session/status`, {
      withCredentials: true
    })
    .then((res) => {
      if (res.data.auth === true) {
        window.location.href = process.env.REACT_APP_SITE_URL
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/membership-settings`)
    .then((res) => {
      setSettings(res.data)
    })
  }, [])

  let redirect = `${process.env.REACT_APP_SITE_URL}/`
  if (props.location.search) {
    const split = props.location.search.split('?redirect=')
    redirect = `${process.env.REACT_APP_SITE_URL}${split[1]}`
  }
  return(
    <div className="wrapper">
      <h1>Sign up</h1>
      <Form
       onFinish={(values) => {
         axios.post(`${process.env.REACT_APP_API_URL}/users`,
           {
             username: values['email'].trim(),
             email: values['email'].trim(),
             password: '34jk343wj3k3!',
             groups: ['6059fdfa9cff022a84a569fe'],
             reset_password: true,
             role: '6074619377e0fd5664bae749',
             confirmed: true
           },
         ).then((res) => {
           message.success('User created')
           props.history.push('/')
         }).catch((err) => {
           message.error(err.response.data.data[0].messages[0].message)
         })
       }}
       onFinishFailed={(values, errors) => {
         console.log(errors)
       }}
       layout="vertical"
      >
      <Form.Item
        label="First name"
        name="first-name"
        rules={[
          {
            required: true,
          }
        ]}
      >
       <Input
         size="large"
       />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="last-name"
        rules={[
          {
            required: true,
          }
        ]}
      >
       <Input size="large" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: 'email'
          }
        ]}
      >
       <Input size="large" />
      </Form.Item>
      <Form.Item
        label="Confirm Email"
        name="email-confirm"
        rules={[
          {
            required: true,
            type: 'email'
          }
        ]}
      >
       <Input size="large" />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
          }
        ]}
      >
      { settings &&
       <Select
        size="large"
        showSearch
        style={{width: '100%'}}
       >
        { settings.low_income_countries.map((item, index) =>
          <Option key={`low-${index}`} income="low" value={item}>{item}</Option>
        )}
        { settings.high_income_countries.map((item, index) =>
          <Option key={`hi-${index}`} income="high" value={item}>{item}</Option>
        )}
       </Select>
     }
      </Form.Item>
      <Form.Item
        name="review-privacy"
        rules={[
          {
            required: true,
          }
        ]}
      >
      <Checkbox.Group>
        <Checkbox value="yes">
        I hereby consent to the processing of my personal data that I have provided for the purposes described in the <a target="blank" href="https://microinsurancenetwork.org/privacy">Privacy statement</a>.
        </Checkbox>
      </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="newsletter-subscribe"
      >
      <Checkbox.Group>
        <Checkbox value="yes">
        I would like to subscribe to the MiN’s monthly newsletter and gain access to fresh content on the latest trends, developments and more in the inclusive insurance space.
        </Checkbox>
      </Checkbox.Group>
      </Form.Item>
      <Button size="large" type="primary" htmlType="submit">Submit</Button>
      </Form>
      <div className="auth-help">
        <p><a href="/">Already have an account? Sign in</a></p>
      </div>
    </div>
  )
}

export default withRouter(SubscriberSignUp)
