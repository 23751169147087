import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { unsubscribeUser } from './helpers.js'
import axios from 'axios'

const Unsubscribe = (props) => {
  const formRef = React.createRef();
  return(
    <div className="wrapper">
      <Form
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          axios.get(`${process.env.REACT_APP_API_URL}/users?email=${values.email}`)
          .then((res) => {
            if (res.data[0]) {
              unsubscribeUser(res.data[0].id)
            } else {
              message.success('Email address unsubscribed')
            }
            formRef.current.resetFields();
          })
          .catch((err) => {
            message.error('An error ocurred')
          })
        }}
      >
      <h1>Unsubscribe</h1>
      <Form.Item
      label="Email address"
      name="email"
      rules={[
        {
          required: true,
          type: 'email'
        }
      ]}
      >
       <Input size="large" placeholder="email" />
      </Form.Item>
      <Button size="large" type="primary" htmlType="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default withRouter(Unsubscribe)
