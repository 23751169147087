import React from 'react'
import './style.css'

const Header = (props) => {
  return (
    <div className="header">
      <a className="logo" href={process.env.REACT_APP_SITE_URL}>Microinsurance Network</a>
    </div>
  )
}

export default Header
