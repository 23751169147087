import React, { useState, useEffect } from 'react'
import { Input, Select, Checkbox, Radio, InputNumber, Form, Button, Row, Col } from 'antd'
import './style.css'
import { numberWithCommas, makeId } from '../../helpers.js'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { getPricingTier } from './helpers'
const { Option } = Select

const SignUpForm = (props) => {
  const [settings, setSettings] = useState()
  const [priceTier, setPriceTier] = useState()
  const [upload1, setUpload1] = useState()
  const [upload2, setUpload2] = useState()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/membership-settings`)
    .then((res) => {
      setSettings(res.data)
      setPriceTier(res.data.corporation_rate_high_income)
    })
  }, [])

  return (
    <div className="wrapper">
      <h1>Institutional Membership</h1>
      <Form
        layout="vertical"
        scrollToFirstError={true}
        onFinish={(values) => {
          const formData = new FormData();
          values['member-price'] = priceTier
          const data = {
            firstName: values['first-name'],
            lastName: values['last-name'],
            phoneNumber: values['phone-number'],
            member_name: values['institution-name'],
            member_email: values['email'],
            member_type: 'Institution',
            member_status: 'pending_validation',
            member_application_data: values,
            member_id: makeId(3),
            language: 'en',
            dateOfJoining: new Date(),
            country: values['country'],
            organisation: values['institution-name'],
            payingPrice: `${priceTier}€`
          }
          if (upload1) {
            formData.append('files.member_financials', upload1, upload1.name)
          }
          if (upload2) {
            formData.append('files.member_report', upload2, upload2.name)
          }
          formData.append('data', JSON.stringify(data))
          axios.post(`${process.env.REACT_APP_API_URL}/members`,
          formData
          ).then((res) => {
            props.history.push('/confirm')
          })
         .catch((err) => {
           console.log(err)
         })
        }}
        onValuesChange={(values, allValues) => {
          const country = allValues['country']
          const institutionType = allValues['institution-type']
          setPriceTier(getPricingTier(country, institutionType, settings))
        }}
      >

      {/*} -------- CONTACT INFO ------------ {*/}
      <div className="form-section">
      <h2>Contact information</h2>
      <p>Please provide your <b>personal contact information</b> so we are able to reach out to you regarding your application for <b>Institutional Membership</b>.</p>
        <Form.Item
          label="First name"
          name="first-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input
           size="large"
         />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="last-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phone-number"
        >
         <Input size="large" />
        </Form.Item>
        </div>



        {/*} -------- INSTITUTIONAL INFO ------------ {*/}
        <div className="form-section">
        <h2>Institutional information</h2>
        <p>Please provide information about the institution seeking membership.</p>
        <Form.Item
          label="Institution name"
          name="institution-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Institution type"
          name="institution-type"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Select
           size="large"
           style={{width: '100%'}}
         >
          <Option value="ASN">Association</Option>
          <Option value="PF">Corporate foundation</Option>
          <Option value="INS">For-profit company</Option>
          <Option value="MI1">Microinsurance company (single country)</Option>
          <Option value="PD">Multilateral organisation</Option>
          <Option value="NGO">NGO, NPO or charity</Option>
          <Option value="PD2">Public donor</Option>
          <Option value="CON">Self-employed consultant</Option>
          <Option value="CON2">Small firm (no more than 10 employees)</Option>
          <Option value="ACH">University or similar institution</Option>
          <Option value="RE">Regulators</Option>
         </Select>
        </Form.Item>
        <Form.Item
          label="Institution website URL"
          name="institution-web-site"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input size="large" placeholder="https://"/>
        </Form.Item>
        <Form.Item
          label="Operational regions"
          name="operational-regions"
          help="Select all that apply"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Select
          mode="multiple"
          size="large"
          style={{width: '100%'}}
          placeholder="Regions"
        >
         <Option value="east-asia-pacific">East Asia and Pacific</Option>
         <Option value="europe-central-asia">Europe and Central Asia</Option>
         <Option value="latin-america-caribbean">Latin America and the Caribbean</Option>
         <Option value="middle-east-north-africa">Middle East and North Africa</Option>
         <Option value="north-america">North America</Option>
         <Option value="south-asia">South Asia</Option>
         <Option value="sub-saharan-africa">Sub-Saharan Africa</Option>
        </Select>
       </Form.Item>
       <Form.Item
         label="Sources of funding"
         name="funding-sources"
         help="Select all that apply"
         rules={[
           {
             required: true,
           }
         ]}
       >
       <Select
         mode="multiple"
         size="large"
         style={{width: '100%'}}
         placeholder="Sources"
       >
        <Option value="donors">Donor funding</Option>
        <Option value="for-profit">For-profit commerical entity</Option>
        <Option value="gifts-donations">Gifts and donations</Option>
        <Option value="governemnt">Government agency</Option>
        <Option value="membership">Membership-based organisation</Option>
        <Option value="other">Other</Option>
       </Select>
      </Form.Item>
      </div>

{/*} -------- ADDRESS ------------ {*/}
<div className="form-section">
      <h2>Address</h2>
      <p>Please provide the address of the institution.</p>
        <Form.Item
          label="Address line 1"
          name="address-line-1"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Address line 2"
          name="address-line-2"
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Postal code"
          name="postal-code"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
            }
          ]}
        >
        { settings &&
         <Select
          size="large"
          showSearch
          style={{width: '100%'}}
         >
          { settings.low_income_countries.map((item, index) =>
            <Option key={`low-${index}`} income="low" value={item}>{item}</Option>
          )}
          { settings.high_income_countries.map((item, index) =>
            <Option key={`hi-${index}`} income="high" value={item}>{item}</Option>
          )}
         </Select>
       }
        </Form.Item>
        </div>

      {/*} -------- INSTITUTIONAL INFO ------------ {*/}
      <div className="form-section">
      <h2>Supporting documentation</h2>
      <p>Prospective institutional members are requested to provide copies of their most recent audited financial statements and annual report. If you currently do not have these documents to hand, you may send them to <a href="mailto:info@microinsurancenetwork.org">info@microinsurancenetwork.org</a> at a later date. Please note that your application will not be reviewed until all necessary documentation has been received. The Microinsurance Network reserves the right to request additional evidence of funding sources when the information provided is not sufficiently clear.</p>
      <Form.Item
        label="Upload latest audited financial statements"
        name="financial-statements-upload"
      >
      <Input type="file" onChange={(e) => {
        setUpload1(e.target.files[0])
      }}
       />
      </Form.Item>
      <Form.Item
        label="URL to latest audited financial statements"
        name="financial-statements-url"
      >
      <Input placeholder="https://" size="large" />
      </Form.Item>
      <Form.Item
        label="Upload latest annual report"
        name="annual-report-upload"
      >
      <Input type="file" onChange={(e) => {
        setUpload2(e.target.files[0])
      }}
      />
      </Form.Item>
      <Form.Item
        label="URL to latest annual report"
        name="annual-report-url"
      >
      <Input placeholder="https://" size="large" />
      </Form.Item>
      </div>

      {/*} -------- PAYMENT INFO ------------ {*/}
      <div className="form-section">
        <h2>Payment information</h2>
        <Row className="product-item" gutter={20}>
        <Col xs={24} sm={16}>
          Annual recurring institutional membership
        </Col>
          <Col xs={24} sm={8}>
            {settings &&
            <div className="price">
            	&euro;
              { priceTier &&
                numberWithCommas(priceTier)
              }
            </div>
            }
            per year
          </Col>
        </Row>
        <Form.Item
          label="Billing email address"
          name="billing-email"
          rules={[
            {
              required: true,
              type: "email"
            }
          ]}
        >
        <Input size="large" />
        </Form.Item>
        <p>An invoice will be sent to the billing email address with instructions on how to make payment.</p>
      </div>


      {/*} -------- PRIVACY INFO ------------ {*/}
      <div className="form-section">
        <h2>Member directory listing</h2>
        <Form.Item
          name="list-member-directory"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Radio.Group>
          <Radio value="yes">
          Yes, list the institution in the public member directory
          </Radio>
          <Radio value="no">
          No, do not list the institution in the public member directory
          </Radio>
        </Radio.Group>
      </Form.Item>
      </div>

      {/*} -------- LEGAL INFO ------------ {*/}
      <div className="form-section">
        <h2>Terms and conditions</h2>
        <Form.Item
          name="review-mission"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Checkbox.Group>
          <Checkbox value="yes">
          I commit to the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/vision-and-mission`}>vision and mission</a>  and have read, understood and agree to support and comply with the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/bylaws`}>by-laws</a> of the Microinsurance Network.
          </Checkbox>
        </Checkbox.Group>

      </Form.Item>
      <Form.Item
        name="review-privacy"
        rules={[
          {
            required: true,
          }
        ]}
      >
      <Checkbox.Group>
      <Checkbox value="yes">
      I hereby consent to the processing of my personal data that I have provided for the purposes described in the <a target="blank" href="https://microinsurancenetwork.org/privacy">Privacy statement</a>.
      </Checkbox>
      </Checkbox.Group>
      </Form.Item>
      </div>


      <div className="form-section">
        <p>Submit your application for instiutional membership by clicking the button below.</p>
        <Button
         htmlType="submit"
         type="primary"
         size="large"
        >
        Submit membership application
        </Button>
        </div>
      </Form>

    </div>
  )
}

export default withRouter(SignUpForm)
