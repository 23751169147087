import Header from './components/Header/'
import InstitutionForm from './components/InstitutionMemberForm/'
import IndividualForm from './components/IndividualMemberForm/'
import InstitutionJoinForm from './components/InstitutionJoinForm/'
import SubscriberSignUp from './components/SubscriberSignUp/'
import ResetPassword from './components/ResetPassword/'
import Unsubscribe from './components/Unsubscribe/'
import Confirmation from './components/Confirmation'
import FormVat from './components/fillOutVAT'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Auth from './components/Auth/'
import "antd/dist/antd.css";
import './App.css';

function App() {
  return (
    <div className="App">
    <Router>
    <Header />
    <Switch>
      <Route exact path="/" render={(props) => <Auth />} />
      <Route exact path="/institutional-application" render={(props) => <InstitutionForm />} />
      <Route exact path="/individual-application" render={(props) => <IndividualForm />} />
      <Route exact path="/instiutional-member-join" render={(props) => <InstitutionJoinForm />} />
      <Route exact path="/reset-password/:id" render={(props) => <ResetPassword />} />
      <Route exact path="/sign-up" render={(props) => <SubscriberSignUp />} />
      <Route exact path="/confirm" render={(props) => <Confirmation />} />
      <Route exact path="/unsubscribe" render={(props) => <Unsubscribe />} />
      <Route exact path="/form-vat" render={(props) => <FormVat />} />



    </Switch>
    </Router>
    </div>
  );
}

export default App;
