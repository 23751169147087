import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons';


const Confirmation = (props) => {
  return(
    <div className="wrapper confirm">
      <div className="icon-success">
        <CheckCircleOutlined />
      </div>
      <h1>Your application has been received!</h1>
      <p>We have received your application. We'll be in touch soon.</p>
      <p>MiN Team</p>
      <a className="ant-btn ant-btn-lg ant-btn-primary"href="https://microinsurancenetwork.org">Return to front page</a>
    </div>
  )
}

export default Confirmation
