import axios from 'axios';
import { makeId } from '../../helpers.js';
import { message } from 'antd';

export const checkUserExists = (values) => {
  axios.get(`${process.env.REACT_APP_API_URL}/users?email=${values.email}`)
  .then((res) => {
    console.log(res)
    if (res.data &&
       res.data[0]) {
      message.warning('User already exists')
    } else {
      checkInviteCode(values)
    }
  })
  .catch((err) => {
    message.success('An error ocurred')
  })
}

export const checkInviteCode = (values) => {
  axios.get(`${process.env.REACT_APP_API_URL}/members?invite_code=${values['invitation-code']}`)
  .then((res) => {
    if (res.data &&
       res.data[0]) {
        createMember(values, res.data[0])
    } else {
      message.warning('Invite code is not valid')
    }
  })
  .catch((err) => {
    message.success('An error ocurred')
  })
}

export const createMember = (values, parent) => {

  return axios.post(`${process.env.REACT_APP_API_URL}/members`,
  {
    firstName: values['first-name'],
    lastName: values['last-name'],
    phoneNumber: values['phone-number'],
    member_name: `${values['first-name']} ${values['last-name']}`,
    member_email: values['email'],
    member_type: 'Beneficiary',
    member_status: 'current',
    member_institution: parent.id,
    member_id: makeId(4),
    member_application_data: values,
    language: 'en',
    dateOfJoining: new Date(),
    country: values['country'],
    payingPrice: '0'
  }).then((res) => {
    return createUser(values, res.data)
  }).then((res) => {
    window.location.href = '/confirm'
  }).catch((err) => {
    console.log(err)
  })
}

export const createUser = (values, parent) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users`,
    {
      username: values['email'].trim(),
      email: values['email'].trim(),
      password: '34jk343wj3k3!',
      groups: ['6059fdfa9cff022a84a569fe', '6059fc8ce2b9bf2a648d39bd'],
      member: parent.id,
      role: '6059f821e47ea22a06aec498',
      reset_password: true,
      confirmed: true
    }).catch((err) => {
    console.log(err)
  })
}
