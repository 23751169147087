import React, { useState, useEffect } from 'react'
import { Input, Select, Checkbox, InputNumber, Form, Row, Col, Button, message } from 'antd'
import { numberWithCommas } from '../../helpers.js'
import { checkUserExists } from './helpers.js'
import axios from 'axios'
import './style.css'
const { Option } = Select

const InstitutionJoinForm = (props) => {
  const [settings, setSettings] = useState()
  const [priceTier, setPriceTier] = useState('high')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/membership-settings`)
    .then((res) => {
      setSettings(res.data)
    })
  }, [])

  return (
    <div className="wrapper">
      <h1>Microinsurance Network</h1>
      <p>Welcome! Use this form to sign up for membership under an existing <b>institutional membership</b>.</p>
      <Form
        layout="vertical"
        scrollToFirstError={true}
        onFinish={(values) => {
          checkUserExists(values)
        }}
        onValuesChange={(values) => {
          if (values['country']) {
            const country = values['country']
            let tier = 'high'
            if (settings.low_income_countries.includes(country)) {
              tier = 'low'
            }
            setPriceTier(tier)
          }
        }}
      >

      {/*} -------- CONTACT INFO ------------ {*/}
      <div className="form-section">
      <h2>Contact information</h2>
        <Form.Item
          label="First name"
          name="first-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input
           size="large"
         />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="last-name"
          rules={[
            {
              required: true,
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Confirm Email"
          name="email-confirm"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phone-number"
        >
         <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
            }
          ]}
        >
        { settings &&
         <Select
          size="large"
          showSearch
          style={{width: '100%'}}
         >
          { settings.low_income_countries.map((item, index) =>
            <Option key={`low-${index}`} income="low" value={item}>{item}</Option>
          )}
          { settings.high_income_countries.map((item, index) =>
            <Option key={`hi-${index}`} income="high" value={item}>{item}</Option>
          )}
         </Select>
       }
        </Form.Item>
        </div>


      {/*} -------- INVITATION CODE ------------ {*/}
      <div className="form-section">
        <h2>Invitation code</h2>
        <p>Please enter your invitation code.</p>
        <Form.Item
        label="Invitation code"
        name="invitation-code"
        rules={[
          {
            required: true,
          }
        ]}
        >
          <Input size="large" />
        </Form.Item>
      </div>

      {/*} -------- LEGAL INFO ------------ {*/}
      <div className="form-section">
        <h2>Terms and conditions</h2>
        <Form.Item
          name="review-mission"
          rules={[
            {
              required: true,
            }
          ]}
        >
        <Checkbox.Group>
          <Checkbox value="yes">
          I commit to the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/vision-and-mission`}>vision and mission</a>  and have read, understood and agree to support and comply with the <a target="blank" href={`${process.env.REACT_APP_SITE_URL}/bylaws`}>by-laws</a> of the Microinsurance Network.
          </Checkbox>
        </Checkbox.Group>

      </Form.Item>
      <Form.Item
        name="review-privacy"
        rules={[
          {
            required: true,
          }
        ]}
      >
      <Checkbox.Group>
      <Checkbox value="yes">
      I hereby consent to the processing of my personal data that I have provided for the purposes described in the <a target="blank" href="https://microinsurancenetwork.org/privacy">Privacy statement</a>.
      </Checkbox>
      </Checkbox.Group>
      </Form.Item>
      </div>


      <div className="form-section">
        <p>Submit your application for membership under an <b>institutional membership</b> below.</p>
        <Button
         htmlType="submit"
         type="primary"
         size="large"
        >
        Submit
        </Button>
        </div>
      </Form>

    </div>
  )
}

export default InstitutionJoinForm
